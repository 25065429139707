<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">
          サンタクロース大使館は安心安全を追求します
        </div>

        <div class="sentent_r1">
          サンタクロース大使館では、ご依頼いただいたお手紙を確実にお届けできるように宛先や宛名をご注文時の情報と照合し漏れや間違い等を確認し、配達事故ゼロを目指して配達状況の確認ができるヤマト運輸と日本郵政の「クロネコゆうパケット」で発送しています。
        </div>

        <h3>フィンランド</h3>

        <div class="centeimg1">
          <img src="../../assets/images/safety1.png" alt="" />
        </div>

        <div class="sentent_g1">
          「サンタクロースからの手紙」は、サンタクロースの国フィンランド
          出身のイラストレーター、ラッセ・カタイネン氏によって描かれた、やわらかで愛らしいイラストの入ったオリジナル封筒に、切手型シールが貼られ、オフィシャルサンタクロースによるオリジナルの消印が押印されています。
        </div>

        <div class="next">
          <img src="../../assets/images/arwbtm.gif" alt="" />
          お手紙はサンタクロース大使館へ
        </div>

        <h3>サンタクロース大使館</h3>

        <div class="centeimg1">
          <img src="../../assets/images/safety2.png" alt="" />
        </div>

        <div class="sentent_g1">
          サンタクロース大使館にて宛先、宛名などを、ご注文いただいた情報と照合し、漏れや間違いがないか確認いたします。
        </div>

        <div class="next">
          <img src="../../assets/images/arwbtm.gif" alt="" />
        </div>

        <div class="centeimg1">
          <img src="../../assets/images/safety3.png" alt="" />
        </div>

        <div class="sentent_g1">
          確認がされたお手紙は、透明袋に入れてヤマト運輸と日本郵政の「クロネコゆうパケット」にて１２月１０日頃から順次発送いたします。<br />
          発送時に、お申し込みいただいたメールアドレスへ「発送のお知らせ」メールをお送りいたします。<br />
          メールに記載の番号で、クロネコヤマトの荷物問い合わせページにて配達状況をご確認いただけます。

          <a
            href="http://toi.kuronekoyamato.co.jp/cgi-bin/tneko?init"
            target="_blank"
            >クロネコヤマト「荷物お問い合わせシステム」</a
          >
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  img {
    width: 10%;
  }
}
.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
    @include sp {
      width: 80%;
    }
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
</style>